import React from 'react';
import { Card, CardContent, CardActions, Typography, Button, List, ListItem, Divider, Box, Container } from '@mui/material';

const spotterPacks = [
  {
    name: '🧙‍♀️Witch Spotter',
    description: 'Created by Paul "Redeye" Chaloner. Reasonably helpful, with the voice of a witch. Install notes included as readme.',
    downloadLink: '/downloads/WitchSpotter.rar', // Update the link to the actual download path
  },
  {
    name: '🎷Jazz Spotter v1',
    description: `Created by esteemed community member Duncasaur. Super memey, lots of long voice lines. Brace yourself. Still there. 🎶 Extract file contents to: %localappdata%\\CrewChiefV4`,
    downloadLink: '/downloads/JazzSpotter_v1.zip', // Update the link to the actual download path
  },
  // ...add more
];

const SpotterPacksPage = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
        py: 8,
        minHeight: '100vh',
      }}
    >
      <Container>
        <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold', mb: 4 }}>
          Spotter Packs
        </Typography>
        <List>
          {spotterPacks.map((pack, index) => (
            <React.Fragment key={pack.name}>
              <ListItem sx={{ justifyContent: 'center' }}>
                <Card
                  sx={{
                    width: '100%',
                    maxWidth: 600,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '12px',
                    mb: 2,
                  }}
                >
                  <CardContent>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                      {pack.name}
                    </Typography>
                    <Typography color="textSecondary">
                      {pack.description}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'flex-end', pr: 2, pb: 2 }}>
                    <Button size="small" variant="contained" color="primary" href={pack.downloadLink} download>
                      Download
                    </Button>
                  </CardActions>
                </Card>
              </ListItem>
              {index < spotterPacks.length - 1 && <Divider variant="middle" sx={{ my: 2 }} />}
            </React.Fragment>
          ))}
        </List>
      </Container>
    </Box>
  );
};

export default SpotterPacksPage;
