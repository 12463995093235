import React from 'react';
import { Card, CardContent, Typography, Button, CardActions } from '@mui/material';
import PropTypes from 'prop-types';

const PricingCard = ({ tier, price, features, onSelect }) => {
  return (
    <Card sx={{ minWidth: 275, maxWidth: 345, margin: 'auto' }}>
      <CardContent>
        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
          {tier}
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: 1.5 }}>
          ${price}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {features.map((feature, index) => (
            <div key={index}>{feature}</div>
          ))}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={onSelect} sx={{ margin: 'auto' }}>Select</Button>
      </CardActions>
    </Card>
  );
};

PricingCard.propTypes = {
  tier: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default PricingCard;
