import React
// , { useState, useEffect } 
from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
// import { useAuth } from '../context/AuthContext'; // Assuming you have an AuthContext



const Layout = () => {
  // const { user } = useAuth();

  return (
    <>
      <Navbar />
      <Outlet />  {/* Where the page content will be rendered */}
      <Footer />
    </>
  );
};

export default Layout;
