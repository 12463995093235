import React from 'react';
import { Box, Container, Grid, Typography, Link } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitch, faDiscord } from '@fortawesome/free-brands-svg-icons';

const links = [
  { label: 'Home', href: '/' },
  { label: 'Pricing', href: '/pricing' },
  { label: 'Spotter Packs', href: '/spotters' }
];

const socialLinks = [
  { icon: faTwitch, href: 'http://www.twitch.tv/dariofr87' },
  { icon: faDiscord, href: 'https://discord.gg/7u6BxWQ6' }
];

const Footer = () => {
  return (
    <Box component="footer" sx={{ backgroundColor: 'background.paper', color: 'text.primary', py: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2">
              Why Not P1 is the premier iRacing coaching service dedicated to helping drivers reach their full potential. Join our community today!
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Links
            </Typography>
            {links.map((link, index) => (
              <Link key={index} href={link.href} color="textSecondary" variant="body2" sx={{ display: 'block', mb: 1 }}>
                {link.label}
              </Link>
            ))}
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Follow Us
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              {socialLinks.map((social, index) => (
                <Link key={index} href={social.href} color="textSecondary">
                  <FontAwesomeIcon icon={social.icon} size="lg" />
                </Link>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} Why Not P1. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
