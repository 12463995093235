import React from 'react';
import { Box, Button } from '@mui/material';
import Save from '@mui/icons-material/Save';

const withSaveButton = (Component, saveChanges) => {
  const WrappedComponent = (props) => (
    <Box>
      <Component {...props} />
      <Box sx={{ p: 3, textAlign: 'right' }}>
        <Button variant="contained" startIcon={<Save />} onClick={saveChanges}>
          Save Changes
        </Button>
      </Box>
    </Box>
  );

  WrappedComponent.displayName = `withSaveButton(${Component.displayName || Component.name || 'Component'})`;

  return WrappedComponent;
};

export default withSaveButton;
