import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, TextField, Typography, Popover, CircularProgress, Alert } from '@mui/material';
import { GlobalContext } from '../context/globalState';
import config from '../config'; // Import config

const AuthPopover = ({ anchorEl, onRequestClose, isRegister, setIsRegister, isOpen }) => {
  const { setUser, setLoading, setError, loading, error } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading();
    setError('');

    const url = isRegister ? `${config.API_BASE_URL}/api/auth/register` : `${config.API_BASE_URL}/api/auth/login`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    const data = await response.json();
    if (response.ok) {
      setUser(data.user);
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
      onRequestClose();
    } else {
      setError(data.message || 'An error occurred');
    }
  };

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onRequestClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        sx: {
          padding: 2,
          minWidth: 300,
          maxWidth: '90vw', // Ensure the popover is responsive
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        },
      }}
    >
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h6" component="h2">
          {isRegister ? 'Register' : 'Login'}
        </Typography>
        {isRegister && (
          <TextField
            label="Username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
            fullWidth
          />
        )}
        <TextField
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
          fullWidth
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          required
          fullWidth
        />
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {error && <Alert severity="error">{error}</Alert>}
            <Button type="submit" variant="contained" color="primary">
              {isRegister ? 'Register' : 'Login'}
            </Button>
          </>
        )}
        <Button
          onClick={() => setIsRegister(!isRegister)}
          variant="text"
          color="secondary"
        >
          {isRegister ? 'Already have an account? Login' : 'Need an account? Register'}
        </Button>
      </Box>
    </Popover>
  );
};

AuthPopover.propTypes = {
  anchorEl: PropTypes.object,
  onRequestClose: PropTypes.func.isRequired,
  isRegister: PropTypes.bool.isRequired,
  setIsRegister: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default AuthPopover;
