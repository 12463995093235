import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  useTheme,
  useMediaQuery,
  Box
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitch, faDiscord } from "@fortawesome/free-brands-svg-icons";
import AuthContext from '../context/AuthContext';
import AuthPopover from './AuthPopover';

const menuItems = [
  { label: "Home", path: "/", icon: "" },
  { label: "Pricing", path: "/pricing", icon: "" },
  { label: "Spotter Packs", path: "/spotters", icon: "" },
  {
    label: "twitch",
    path: "http://www.twitch.tv/dariofr87",
    icon: faTwitch,
    external: true,
  },
  {
    label: "discord",
    path: "https://discord.gg/6sSmkyFq2K",
    icon: faDiscord,
    external: true,
  },
];

const Navbar = () => {
  const { user, logout } = useContext(AuthContext);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElAuth, setAnchorElAuth] = useState(null);
  const [isRegister, setIsRegister] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenAuthPopover = (event) => {
    setAnchorElAuth(event.currentTarget);
  };

  const handleCloseAuthPopover = () => {
    setAnchorElAuth(null);
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <img src="/WhyNotP1/WhyNotP1/Logo/Full Colour/Web/whynotp1-logo-full-colour-rgb-3000px-w-72ppi.png" alt="Logo" style={{ height: 40, marginRight: theme.spacing(2) }} />
        </Box>
        {isMobile ? (
          <>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {menuItems.map((item) => (
                <MenuItem
                  key={item.label}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={item.path}
                >
                  <Typography textAlign="center">
                    {item.icon && <FontAwesomeIcon icon={item.icon} style={{ marginRight: 8 }} />}
                    {item.label}
                  </Typography>
                </MenuItem>
              ))}
              {user && user.role === 'admin' && (
                <MenuItem
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to="/admin"
                >
                  <Typography textAlign="center">Admin</Typography>
                </MenuItem>
              )}
              {!user && (
                <MenuItem onClick={handleOpenAuthPopover}>
                  <Typography textAlign="center">Login/Register</Typography>
                </MenuItem>
              )}
              {user && (
                <MenuItem onClick={logout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              )}
            </Menu>
          </>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {menuItems.map((item) => (
              <Button
                key={item.label}
                component={item.external ? 'a' : Link}
                href={item.external ? item.path : undefined}
                to={item.external ? undefined : item.path}
                target={item.external ? "_blank" : undefined}
                rel={item.external ? "noopener noreferrer" : undefined}
              >
                {item.icon && <FontAwesomeIcon icon={item.icon} style={{ marginRight: 8 }} />}
                {item.label}
              </Button>
            ))}
            {user && user.role === 'admin' && (
              <Button
                component={Link}
                to="/admin"
              >
                Admin
              </Button>
            )}
            {!user && (
              <Button
                onClick={handleOpenAuthPopover}
              >
                Login/Register
              </Button>
            )}
            {user && (
              <Button
                onClick={logout}
              >
                Logout
              </Button>
            )}
          </Box>
        )}
      </Toolbar>
      <AuthPopover
        anchorEl={anchorElAuth}
        onRequestClose={handleCloseAuthPopover}
        isRegister={isRegister}
        setIsRegister={setIsRegister}
        isOpen={Boolean(anchorElAuth)}
      />
    </AppBar>
  );
};

Navbar.propTypes = {
  anchorElAuth: PropTypes.object,
  onRequestCloseAuthPopover: PropTypes.func.isRequired,
  isRegister: PropTypes.bool.isRequired,
  setIsRegister: PropTypes.func.isRequired,
  isOpenAuthPopover: PropTypes.bool.isRequired,
};

export default Navbar;
