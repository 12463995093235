// src/components/SponsorSection.js

import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const sponsors = [
  { name: 'Estrella Bandito', logo: '/estrella.png' },
  { name: 'griphax', logo: '/griphax.png' },
  { name: 'noboeuf', logo: '/noboeuf.png' },
  { name: 'pod2pass', logo: '/pod2pass.png' },
  { name: 'puntweiser', logo: '/puntweiser.png' },
  { name: 'smoothbrain', logo: '/smoothbrain.png' },
  // Add more sponsors as needed
];

const SponsorSection = () => {
    return (
      <Box
        sx={{
          py: 8,
          background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ textAlign: 'center', mb: 4, fontWeight: 'bold' }}
        >
          Our Sponsors
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {sponsors.map((sponsor, i) => (
            <Grid item xs={6} sm={4} md={3} key={i}>
              <Box
                sx={{
                  width: '100%',
                  height: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                <Box
                  component="img"
                  src={sponsor.logo}
                  alt={sponsor.name}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: '150px', // Set maximum width for uniform size
                    filter: 'grayscale(100%)',
                    transition: 'filter 0.3s, transform 0.3s',
                    '&:hover': {
                      filter: 'none',
                      transform: 'scale(1.05)',
                    },
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };
  
  export default SponsorSection;
