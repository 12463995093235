import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Parallax } from 'react-parallax';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const defaultHeroInfo = {
  imageLocation: "/hero.png",
  mainTagline: "Why Not P1",
  secondaryTagLine: "iRacing Coaching Service",
  buttonText: "I Want Pace",
};

const HeroSection = ({ heroInfo: propsHeroInfo }) => {
  const [heroInfo, setHeroInfo] = useState(defaultHeroInfo);
  const navigate = useNavigate();

  useEffect(() => {
    if (propsHeroInfo) {
      setHeroInfo(propsHeroInfo);
    } else {
      const fetchHeroInfo = async () => {
        try {
          const response = await fetch('/api/hero');
          if (!response.ok) throw new Error('Network response was not ok');
          const data = await response.json();
          setHeroInfo(data);
        } catch (err) {
          console.error('Failed to fetch hero info, using default values');
        }
      };

      fetchHeroInfo();
    }
  }, [propsHeroInfo]);

  const handleButtonClick = () => {
    navigate('/pricing'); // Navigates to the pricing page
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: { xs: '60vh', md: '80vh' }, // Responsive height for mobile and desktop
          overflow: 'hidden',
        }}
      >
        <Parallax
          bgImage={heroInfo.imageLocation}
          strength={500}
          bgImageStyle={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
          }}
          style={{
            height: '110%',
            width: '100%',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.4)', // Dark shade overlay
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              color: 'white',
              padding: { xs: 2, md: 4 },
              boxSizing: 'border-box', // Ensure padding doesn't add to the box's size
            }}
          >
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{ fontSize: { xs: '2rem', md: '3rem' }, fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}
            >
              {heroInfo.mainTagline}
            </Typography>
            <Typography
              variant="h5"
              sx={{ mb: 4, fontSize: { xs: '1rem', md: '1.5rem' }, textShadow: '1px 1px 3px rgba(0,0,0,0.5)' }}
            >
              {heroInfo.secondaryTagLine}
            </Typography>
            <Button
              variant="contained"
              size="large"
              sx={{
                backgroundColor: 'primary.main',
                color: 'white',
                '&:hover': { backgroundColor: 'secondary.main' },
                padding: { xs: '10px 20px', md: '15px 30px' },
                fontSize: { xs: '0.875rem', md: '1rem' },
                boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
              }}
              onClick={handleButtonClick}
            >
              {heroInfo.buttonText}
            </Button>
          </Box>
        </Parallax>
      </Box>
      <style>
        {`
          .react-parallax-content {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}
      </style>
    </>
  );
};

HeroSection.propTypes = {
  heroInfo: PropTypes.shape({
    imageLocation: PropTypes.string,
    mainTagline: PropTypes.string,
    secondaryTagLine: PropTypes.string,
    buttonText: PropTypes.string,
  }),
};

export default HeroSection;
