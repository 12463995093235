// src/App.js
import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { GlobalProvider } from "./context/globalState"
import { ThemeProvider } from "@mui/material/styles"
import Layout from "./components/Layout"
import HomePage from "./Pages/HomePage"
import PricingPage from "./Pages/PricingPage"
import SpotterPacks from "./Pages/SpotterPacks"
import AdminPage from "./Pages/AdminPage" // Add this import
import { AuthProvider } from "./context/AuthContext"
import { AdminProvider } from "./context/AdminContext"
import NotFound from "./components/NotFound"
import ProtectedRoute from "./components/ProtectedRoute"
import theme from "./theme"
import PreviewPage from './Pages/PreviewPage';

const App = () => {
    return (
        <GlobalProvider>
            <AuthProvider>
                <AdminProvider>
                    <ThemeProvider theme={theme}>
                        <Router>
                            <Routes>
                                <Route path="/" element={<Layout />}>
                                    <Route index element={<HomePage />} />
                                    <Route
                                        path="pricing"
                                        element={<PricingPage />}
                                    />
                                    <Route
                                        path="spotters"
                                        element={<SpotterPacks />}
                                    />
                                    <Route path="/preview" element={<PreviewPage />} />
                                    <Route
                                        path="admin"
                                        element={
                                            <ProtectedRoute>
                                                <AdminPage />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route path="*" element={<NotFound />} />
                                </Route>
                            </Routes>
                        </Router>
                    </ThemeProvider>
                </AdminProvider>
            </AuthProvider>
        </GlobalProvider>
    )
}

export default App
