// src/components/FeatureSection.js
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const FeatureSection = ({ image, title, description, reverse, hasLink, linkUrl, linkText }) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={3}
      sx={{
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        '&:hover': { transform: 'scale(1.02)', transition: '0.3s' },
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.background.paper,
        boxShadow: theme.shadows[4],
      }}
    >
      <Grid container spacing={4} direction={reverse ? 'row-reverse' : 'row'} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={image}
            alt={title}
            sx={{ width: '100%', height: 'auto', borderRadius: theme.shape.borderRadius, transition: '0.3s', '&:hover': { transform: 'scale(1.05)' } }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {description}
          </Typography>
          {hasLink && (
            <Button
              component={Link}
              to={linkUrl}
              variant="contained"
              color="primary"
              sx={{ alignSelf: 'center', mt: 2 }}
            >
              {linkText}
            </Button>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

FeatureSection.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
  hasLink: PropTypes.bool,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
};

FeatureSection.defaultProps = {
  reverse: false,
  hasLink: false,
  linkUrl: '#',
  linkText: '',
};

export default FeatureSection;
