// src/components/common/ReusableForm.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, TextField, FormControlLabel, Checkbox } from '@mui/material';

const ReusableForm = ({ fields, initialData, onSubmit }) => {
  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {fields.map(({ name, label, type, disabled }) => (
        <React.Fragment key={name}>
          {type === 'checkbox' ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData[name] || false}
                  onChange={handleChange}
                  name={name}
                />
              }
              label={label}
            />
          ) : (
            <TextField
              label={label}
              name={name}
              value={formData[name] || ''}
              onChange={handleChange}
              fullWidth
              type={type || 'text'}
              disabled={disabled}
            />
          )}
        </React.Fragment>
      ))}
      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </Box>
  );
};

ReusableForm.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  initialData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ReusableForm;
