import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import AdminSection from './AdminTabs/AdminSection';
import { AdminProvider } from '../context/AdminContext';
import PropTypes from 'prop-types';

const AdminPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSwipe = (delta) => {
    const newIndex = selectedTab + delta;
    if (newIndex >= 0 && newIndex < tabConfigurations.length) {
      setSelectedTab(newIndex);
    }
  };

  const tabConfigurations = [
    { label: 'Users', sectionName: 'users', componentName: 'UserSection' },
    { label: 'Features', sectionName: 'features', componentName: 'FeatureSection' },
    // { label: 'Posts', itemType: 'posts', componentName: 'PostSection' },
    { label: 'Hero', sectionName: 'heroes', componentName: 'HeroSection' },
    { label: 'Pricing', sectionName: 'pricing', componentName: 'PricingItem' },
  ];

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe(1),
    onSwipedRight: () => handleSwipe(-1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <AdminProvider>
      <Box sx={{ p: 3 }} {...swipeHandlers}>
        <Typography variant="h4" gutterBottom>
          Admin Panel
        </Typography>
        <Tabs value={selectedTab} onChange={handleChange} aria-label="admin tabs">
          {tabConfigurations.map((tab, index) => (
            <Tab label={tab.label} key={index} />
          ))}
        </Tabs>
        <Box>
          {tabConfigurations.map((tab, index) => (
            <TabPanel value={selectedTab} index={index} key={index}>
              <AdminSection sectionName={tab.sectionName} componentName={tab.componentName} />
            </TabPanel>
          ))}
        </Box>
      </Box>
    </AdminProvider>
  );
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`admin-tabpanel-${index}`}
      aria-labelledby={`admin-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, minHeight: '60vh' }}>
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
};

export default AdminPage;
