// src/Pages/PreviewPage.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import PreviewComponent from '../components/common/PreviewComponent';

const PreviewPage = () => {
  const location = useLocation();
  const { itemData, componentName } = location.state || {}; // Retrieve data from location state

  if (!itemData || !componentName) {
    return <div>No data to preview</div>;
  }

  return <PreviewComponent componentName={componentName} itemData={itemData} />;
};

export default PreviewPage;
