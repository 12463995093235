import React, { useEffect, useContext } from 'react';
import HeroSection from '../components/Hero';
import FeatureSection from '../components/Features';
import SponsorSection from '../components/SponsorSection';
import { Container, Grid, Box, useTheme } from '@mui/material';
import { AdminContext } from '../context/AdminContext';

const HomePage = () => {
  const { features, heroes, fetchItems } = useContext(AdminContext);
  const theme = useTheme();
  const defaultHeroInfo = {
    imageLocation: "/hero.png",
    mainTagline: "Why Not P1",
    secondaryTagLine: "iRacing Coaching Service",
    buttonText: "I Want Pace",
  };

  useEffect(() => {
    fetchItems('features');
    fetchItems('heroes');
  }, []);

  const currentHero = heroes.find(hero => hero.isCurrent) || defaultHeroInfo;

  const renderFeatures = () => {
    return features
      .filter((feature) => feature.visible)
      .sort((a, b) => a.order - b.order)
      .map((feature, i) => (
        <Grid item xs={12} key={`Featurekey${i}`}>
          <FeatureSection
            image={feature.image}
            title={feature.title}
            description={feature.description}
            reverse={feature.reverse}
            hasLink={feature.hasLink}
            linkUrl={feature.linkUrl}
            linkText={feature.linkText}
          />
        </Grid>
      ));
  };

  return (
    <>
      <HeroSection heroInfo={currentHero} />
      <Box
        sx={{
          background: theme.palette.background.gradient,
          py: theme.spacing(8),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={4} justifyContent="center">
            {renderFeatures()}
          </Grid>
        </Container>
      </Box>
      <SponsorSection />
    </>
  );
};

export default HomePage;
