import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Alert, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Refresh from '@mui/icons-material/Refresh';
import { AdminContext } from '../../context/AdminContext';
import ReusableTable from '../../components/common/ReusableTable';
import ReusableForm from '../../components/common/ReusableForm';
import withSaveButton from '../../components/common/withSaveButton';
import PreviewComponent from '../../components/common/PreviewComponent';
import { componentMapping } from '../../utils/componentMapping';
import PropTypes from 'prop-types';

const AdminSection = ({ sectionName }) => {
  const { fetchItems, addItem, updateItem, deleteItem, loading, error } = useContext(AdminContext);
  const [localItems, setLocalItems] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [previewItem, setPreviewItem] = useState(null);

  useEffect(() => {
    if (!sectionName) {
      console.error('sectionName is undefined');
      return;
    }
    const initializeItems = async () => {
      const data = await fetchItems(sectionName);
      console.log(`Fetched ${sectionName} Items:`, data); // Debug log
      setLocalItems(data);
      if (data.length > 0) {
        generateFormFields(data[0]);
        generateTableColumns(data[0]);
      }
    };

    initializeItems();
  }, [sectionName]);

  const generateFormFields = (item) => {
    const fields = Object.keys(item).map(key => {
      if (key === '_id' || key === '__v') return null;
      return {
        name: key,
        label: key.charAt(0).toUpperCase() + key.slice(1),
        type: typeof item[key] === 'boolean' ? 'checkbox' : (typeof item[key] === 'number' ? 'number' : 'text')
      };
    }).filter(Boolean);
    setFormFields(fields);
  };

  const generateTableColumns = (item) => {
    const columns = Object.keys(item).map(key => {
      if (key === '_id' || key === '__v') return null;
      return {
        field: key,
        headerName: key.charAt(0).toUpperCase() + key.slice(1),
        type: typeof item[key] === 'boolean' ? 'checkbox' : (typeof item[key] === 'number' ? 'number' : 'text')
      };
    }).filter(Boolean);
    setTableColumns(columns);
  };

  const handleFormSubmit = async (formData) => {
    if (formData.features && typeof formData.features === 'string') {
      formData.features = formData.features.split(',').map((f) => f.trim());
    }

    if (isEditing) {
      await updateItem(sectionName, editingItem._id, formData);
      setIsEditing(false);
      setEditingItem(null);
    } else {
      await addItem(sectionName, formData);
    }
    fetchItems(sectionName).then(setLocalItems);
    setAccordionOpen(false);
  };

  const handleEdit = (item) => {
    setEditingItem(item);
    setIsEditing(true);
    setAccordionOpen(true);
  };

  const handleDelete = async (id) => {
    await deleteItem(sectionName, id);
    fetchItems(sectionName).then(setLocalItems);
  };

  const handlePreview = (item) => {
    setPreviewItem(item);
  };

  // const saveChanges = async () => {
  //   for (const item of localItems) {
  //     await updateItem(sectionName, item._id, item);
  //   }
  //   fetchItems(sectionName).then(setLocalItems);
  // };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        {`${sectionName.charAt(0).toUpperCase() + sectionName.slice(1)} Management`}
      </Typography>
      <Button variant="outlined" startIcon={<Refresh />} onClick={() => fetchItems(sectionName).then(setLocalItems)} sx={{ mb: 2 }}>
        Refresh {`${sectionName.charAt(0).toUpperCase() + sectionName.slice(1)}`} Items
      </Button>
      <Accordion expanded={accordionOpen} onChange={() => setAccordionOpen(!accordionOpen)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{isEditing ? `Edit ${sectionName.slice(0, -1)} Item` : `Add ${sectionName.slice(0, -1)} Item`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {formFields.length > 0 ? (
            <ReusableForm fields={formFields} initialData={editingItem || {}} onSubmit={handleFormSubmit} />
          ) : (
            <Typography>No fields available to display the form.</Typography>
          )}
        </AccordionDetails>
      </Accordion>
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      <ReusableTable
        items={localItems}
        columns={tableColumns}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onPreview={handlePreview}
        onVisibilityChange={() => {}} // No visibility change required for this example
        onOrderChange={() => {}} // No order change required for this example
      />
      {previewItem && <PreviewComponent componentName={componentMapping[sectionName]} itemData={previewItem} />}
    </Box>
  );
};

AdminSection.propTypes = {
  sectionName: PropTypes.string.isRequired,
};

export default withSaveButton(AdminSection);
