import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

// Initial state
const initialState = {
  user: null,
  drivers: [],
  loading: false,
  error: null,
};

// Create context
export const GlobalContext = createContext(initialState);

// Reducer
const AppReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };
    case 'SET_DRIVERS':
      return {
        ...state,
        drivers: action.payload,
        loading: false,
        error: null,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

// Provider component
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions
  const setLoading = () => {
    dispatch({ type: 'SET_LOADING' });
  };

  const setUser = (user) => {
    dispatch({ type: 'SET_USER', payload: user });
  };

  const setDrivers = (drivers) => {
    dispatch({ type: 'SET_DRIVERS', payload: drivers });
  };

  const setError = (error) => {
    dispatch({ type: 'SET_ERROR', payload: error });
  };

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <GlobalContext.Provider
      value={{
        user: state.user,
        drivers: state.drivers,
        loading: state.loading,
        error: state.error,
        setLoading,
        setUser,
        setDrivers,
        setError,
        logout,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
