import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import config from '../config';

// Initial state
const initialState = {
  users: [],
  features: [],
  heroes: [],
  pricing: [], // Ensure this is 'pricing'
  posts: [],
  loading: false,
  error: null,
};

// Create context
export const AdminContext = createContext(initialState);

// Reducer
const adminReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: true };
    case 'SET_ITEMS':
      return { ...state, [action.payload.type]: action.payload.items, loading: false, error: null };
    case 'SET_ERROR':
      return { ...state, error: action.payload, loading: false };
    case 'ADD_ITEM':
      return { ...state, [action.payload.type]: [...state[action.payload.type], action.payload.item] };
    case 'UPDATE_ITEM':
      return {
        ...state,
        [action.payload.type]: state[action.payload.type].map((item) =>
          item._id === action.payload.item._id ? action.payload.item : item
        ),
      };
    case 'DELETE_ITEM':
      return {
        ...state,
        [action.payload.type]: state[action.payload.type].filter((item) => item._id !== action.payload.id),
      };
    default:
      return state;
  }
};

// Provider component
export const AdminProvider = ({ children }) => {
  const [state, dispatch] = useReducer(adminReducer, initialState);

  // Actions
  const setLoading = () => dispatch({ type: 'SET_LOADING' });

  const setItems = (type, items) => dispatch({ type: 'SET_ITEMS', payload: { type, items } });

  const setError = (error) => dispatch({ type: 'SET_ERROR', payload: error });

  const fetchItems = async (type) => {
    setLoading();
    try {
      const response = await fetch(`${config.API_BASE_URL}/api/${type}`);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setItems(type, data);
      return data;
    } catch (error) {
      setError(error.message);
    }
  };

  const addItem = async (type, item) => {
    setLoading();
    try {
      const response = await fetch(`${config.API_BASE_URL}/api/${type}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item),
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Failed to add item');
      dispatch({ type: 'ADD_ITEM', payload: { type, item: data } });
    } catch (error) {
      setError(error.message);
    }
  };

  const updateItem = async (type, id, item) => {
    setLoading();
    try {
      const response = await fetch(`${config.API_BASE_URL}/api/${type}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item),
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Failed to update item');
      dispatch({ type: 'UPDATE_ITEM', payload: { type, item: data } });
    } catch (error) {
      setError(error.message);
    }
  };

  const deleteItem = async (type, id) => {
    setLoading();
    try {
      const response = await fetch(`${config.API_BASE_URL}/api/${type}/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) throw new Error('Failed to delete item');
      dispatch({ type: 'DELETE_ITEM', payload: { type, id } });
    } catch (error) {
      setError(error.message);
    }
  };

  const setCurrentHero = async (id) => {
    setLoading();
    try {
      await fetch(`${config.API_BASE_URL}/api/heroes/setCurrent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      });
      await fetchItems('heroes');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <AdminContext.Provider
      value={{
        ...state,
        fetchItems,
        addItem,
        updateItem,
        deleteItem,
        setCurrentHero,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

AdminProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
