// src/utils/handlePreview.js
import { useNavigate } from 'react-router-dom';

const useHandlePreview = () => {
  const navigate = useNavigate();

  const handlePreview = (componentName, itemData) => {
    const styles = {
      container: { /* your styles here */ },
      // Define styles for the specific component here if needed
    };

    navigate('/preview', {
      state: {
        componentName,
        itemData,
        styles,
      },
    });
  };

  return handlePreview;
};

export default useHandlePreview;
