import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Checkbox } from '@mui/material';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import useHandlePreview from '../../utils/handlePreview';
import config from '../../config';

const isImageUrl = (url) => {
  const imagePattern = /\.(jpeg|jpg|gif|png|svg|webp|bmp|tiff|ico)(\?.*)?$/i;
  return typeof url === 'string' && imagePattern.test(url);
};

const renderCellContent = (value) => {
  if (typeof value === 'object' && value !== null) {
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    return JSON.stringify(value);
  }
  return value;
};

const ReusableTable = ({ items, columns, onEdit, onDelete, onVisibilityChange, componentName }) => {
  const handlePreview = useHandlePreview();

  const handleIsCurrentChange = async (id) => {
    try {
      await fetch(`${config.API_BASE_URL}/api/heroes/setCurrent`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      });
      onVisibilityChange();
    } catch (error) {
      console.error('Failed to set current hero:', error);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.field}>{column.headerName}</TableCell>
            ))}
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item._id}>
              {columns.map((column) => (
                <TableCell key={column.field}>
                  {column.type === 'checkbox' ? (
                    column.field === 'isCurrent' ? (
                      <Checkbox
                        checked={item[column.field]}
                        onChange={() => handleIsCurrentChange(item._id)}
                      />
                    ) : (
                      <Checkbox checked={item[column.field]} readOnly />
                    )
                  ) : column.type === 'number' ? (
                    <input type="number" value={item[column.field]} readOnly />
                  ) : isImageUrl(item[column.field]) ? (
                    <img src={item[column.field]} alt={column.field} style={{ width: '50px' }} />
                  ) : (
                    renderCellContent(item[column.field])
                  )}
                </TableCell>
              ))}
              <TableCell>
                <IconButton onClick={() => onEdit(item)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => onDelete(item._id)}>
                  <Delete />
                </IconButton>
                <IconButton onClick={() => handlePreview(componentName, item)}>
                  <Visibility />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ReusableTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
      type: PropTypes.string,
    })
  ).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  componentName: PropTypes.string.isRequired,
};

export default ReusableTable;
