import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Box, useTheme } from '@mui/material';
import HeroSection from '../Hero';
import FeatureSection from '../Features';
import PricingCard from '../PricingCard';

const componentMap = {
  HeroSection,
  FeatureSection,
  PricingCard,
  // Add other component mappings here as needed
};

const PreviewComponent = ({ componentName, itemData }) => {
  const Component = componentMap[componentName];
  const theme = useTheme();

  if (!Component) {
    return <div>Component not found</div>;
  }

  if (componentName === 'HeroSection') {
    return (
      <Box sx={{ background: theme.palette.background.default }}>
        <Component {...itemData} />
      </Box>
    );
  }

  if (componentName === 'PricingCard') {
    return (
      <Box
        sx={{
          background: theme.palette.background.default,
          py: theme.spacing(8),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <Component {...itemData} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        py: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12}>
            <Component {...itemData} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

PreviewComponent.propTypes = {
  componentName: PropTypes.string.isRequired,
  itemData: PropTypes.object.isRequired,
};

export default PreviewComponent;
