import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();

  // Fallback to localStorage if user is not in context
  const storedUser = user || JSON.parse(localStorage.getItem('user'));
  
  console.log('user from protected route', storedUser);

  if (!storedUser || storedUser.role !== 'admin') {
    return <Navigate to="/" />;
  }

  return children ? children : <Outlet />;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
};

export default ProtectedRoute;
