import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: { main: '#E50068' },
    secondary: { main: '#D9C31A' },
    error: { main: '#ff5252' },
    background: {
      default: '#333', // Dark background color
      paper: '#444', // Slightly lighter color for feature sections
      gradient: '#333', // Solid dark color for gradient
    },
    text: {
      primary: '#fff',
      secondary: '#aaaaaa',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: { fontSize: '2.5rem', fontWeight: 700 },
    h2: { fontSize: '2.2rem', fontWeight: 700 },
    h4: { fontSize: '1.5rem', fontWeight: 500 },
    h6: { fontSize: '1.25rem', fontWeight: 500 },
    body1: { fontSize: '1rem', fontWeight: 400, color: '#555' },
    body2: { fontSize: '0.875rem', fontWeight: 400, color: '#777' },
    button: { textTransform: 'none', fontWeight: 600 },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          backgroundColor: '#333', // Dark background color
          color: '#555',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        },
        footer: { paddingTop: '0 !important', marginTop: '0 !important' },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h3: { fontWeight: 'bold', marginBottom: '1rem' },
        h6: { marginBottom: '0.5rem' },
        body1: { color: '#555' },
        body2: { color: '#777' },
      },
    },
    MuiAppBar: {
      styleOverrides: { root: { backgroundColor: '#333', color: 'white' } },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          marginLeft: '8px',
          '&:hover': { backgroundColor: '#D9C31A' },
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          '&.MuiBox-root': {
            position: 'relative',
            overflow: 'hidden',
            padding: '2rem 0',
            minHeight: '100vh',
            background: '#333', // Dark background color
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#444', // Slightly lighter color for feature sections
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
        },
      },
    },
    MuiToolbar: {
      styleOverrides: { root: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } },
    },
    MuiGrid: {
      styleOverrides: {
        container: { justifyContent: 'center' },
        item: { textAlign: 'center' },
      },
    },
    MuiDivider: {
      styleOverrides: { root: { marginY: '2rem' } },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#aaaaaa',
          '&:hover': { color: '#ffffff' },
          '& input[type=number]': { '-moz-appearance': 'textfield' },
          '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        },
      },
    },
  },
});

export default theme;
